import { CollapseProps, Collapse, Col, Row, Divider } from "antd";
import * as React from "react";
import "../../common/common.css";
import {
  ArrowRightOutlined,
  BookOutlined,
  BranchesOutlined,
  IssuesCloseOutlined,
  PartitionOutlined,
  ProductOutlined,
  ProjectOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AppRoutesPath } from "../../Routes/AppRoutesPath";
import { Link } from "react-router-dom";
import { isUserAdmin, isUserTeamLeader } from "../../utils/Util";
interface ISettingProps {}

export const Setting = (props: ISettingProps) => {
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <span style={{ fontWeight: 600 }} className="mt-4 mb-4">
          Settings
        </span>
      ),
      children: (
        <>
          <Link to={`${AppRoutesPath.setting.project}`}>
            <Row justify={"space-between"} align={"middle"} className="mt-3">
              <Col span={4}>
                <ProjectOutlined className="settingsIcon mr-2 ml-3 " />
                <span> My Project</span>
              </Col>
              <Col className="mr-5">
                <ArrowRightOutlined />
              </Col>
            </Row>
            <Divider className="mt-3 mb-3" />
          </Link>

          {/* {isUserAdmin() && ( */}
          <Link to={`${AppRoutesPath.setting.issue}`}>
            <Row justify="space-between" align="middle">
              <Col span={4}>
                <IssuesCloseOutlined  className="settingsIcon mr-2 ml-3" />
                <span>issue</span>
              </Col>
              <Col className="mr-5">
                <ArrowRightOutlined />
              </Col>
            </Row>
            <Divider className="mt-3 mb-3" />
          </Link>
          {/* )} */}

          <Link to={`${AppRoutesPath.setting.sprint}`}>
            <Row justify={"space-between"} align={"middle"}>
              <Col span={4}>
                <PartitionOutlined className="settingsIcon mr-2 ml-3 " />
                <span> Sprint</span>
              </Col>
              <Col className="mr-5">
                <ArrowRightOutlined />
              </Col>
            </Row>
            <Divider className="mt-3 mb-3" />
          </Link>

          <Link to={`${AppRoutesPath.setting.branch}`}>
            <Row justify={"space-between"} align={"middle"}>
              <Col span={4}>
                <BranchesOutlined className="settingsIcon mr-2 ml-3 " />
                <span> Branch</span>
              </Col>
              <Col className="mr-5">
                <ArrowRightOutlined />
              </Col>
            </Row>
            <Divider className="mt-3 mb-3" />
          </Link>

          {isUserAdmin() && (
          <Link to={`${AppRoutesPath.setting.user}`}>
            <Row justify={"space-between"} align={"middle"}>
              <Col span={4}>
                <UserOutlined className="settingsIcon mr-2 ml-3 " />
                <span>User</span>
              </Col>
              <Col className="mr-5">
                <ArrowRightOutlined />
              </Col>
            </Row>
            <Divider className="mt-3 mb-3" />
          </Link>
        )} 
          {isUserAdmin() && (
          <Link to={`${AppRoutesPath.setting.group}`}>
            <Row justify={"space-between"} align={"middle"}>
              <Col span={4}>
                <UsergroupAddOutlined  className="settingsIcon mr-2 ml-3 " />
                <span>Group</span>
              </Col>
              <Col className="mr-5">
                <ArrowRightOutlined />
              </Col>
            </Row>
            <Divider className="mt-3 mb-3" />
          </Link>
          )} 
          {/* {isUserAdmin() && (
            <Link to={`${AppRoutesPath.setting.teamleader}`}>
              <Row justify={"space-between"} align={"middle"}>
                <Col span={4}>
                  <TeamOutlined className="settingsIcon mr-2 ml-3 " />
                  <span>Team Leaders</span>
                </Col>
                <Col className="mr-5">
                  <ArrowRightOutlined />
                </Col>
              </Row>
              <Divider className="mt-3 mb-3" />
            </Link>
          )} */}
        </>
      ),
    },
  ];
  return (
    <>
      <Collapse
        className="mt-4 CollapseSetting"
        size={"middle"}
        items={items}
        defaultActiveKey={["1"]}
        onChange={onChange}
      />
    </>
  );
};
