import React, { useMemo, useState } from "react";
import AddEditProject from "./AddEditProject";
import {
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  Input,
  message,
  Row,
  Table,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import {
  useGetProjects,
  useGetProjectsWithStatusCounting,
} from "../../Services/RQDashboardService";
import { AssigneeAvatar } from "../dashboard/Common/AssigneeAvatar";

import {
  ArrowLeftOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "../../common/common.css";
import { Link } from "react-router-dom";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import {
  getEllipsisText,
  isUserAdmin,
  isUserTeamLeader,
} from "../../utils/Util";
import { queryClient } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import { log } from "console";
const { Search } = Input;
interface ProjectData {
  assigneeIds: { label: any; value: any }[];
  assignee: any[];
}

const ProjectList = () => {
  const user = useSelector(userData);
  const [show, setShow] = useState("");
  const [data, setData] = useState<ProjectData | null>(null);
  const onEdit = (values: { assignee: any[] }) => {
    const val = {
      ...values,
      assigneeIds: values?.assignee?.map((itm) => ({
        label: itm?.name,
        value: itm?.id,
      })),
    };
    setData(val);
    setShow("AddEditProject");
  };
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
    projectNameSearch: "",
  });
  const {
    isFetching,
    isLoading,
    isPending,
    data: projectDataList,
  } = useGetProjectsWithStatusCounting({
    ...listParams,
    assigneeId: user?.id,
  });
  const apiProjectData = useMemo(() => {
    if (projectDataList?.message)
      message.error(
        `Error in Project list fetching => ${projectDataList?.message}`
      );
    const data = {
      list:
        projectDataList?.result?.items?.map((project: any) => ({
          ...project,
          imagePath:
            project?.imagePath === "?t=" ? undefined : project?.imagePath,
        })) || [],
      totalRecords: projectDataList?.result?.totalRecords || 0,
    };
    return data;
  }, [user, projectDataList]);

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    //setListParams((x: any) => ({ ...x }));
  };
  const [refresh, setRefresh] = useState<boolean>(false);
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  const columns: any = [
    {
      title: "S. No.",
      dataIndex: "sno",
      key: "serial",
      width: "9%",
      ellipsis: true,
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      showSorterTooltip: false,
      render: (text: any, record: any) => (
        <div
        className="ProjectNameColor"
         title={text}
          // ellipsis
          onClick={() => onEdit(record)}
          style={{ cursor: "pointer",
            whiteSpace:"nowrap",
            overflow:"hidden",
            textOverflow: "ellipsis"
          }}
        >
          {text}
        </div>
      ),
    },
    {
      sorter: (a: any, b: any) => a.alias.localeCompare(b.alias),
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      width: "10%",
      showSorterTooltip: false,
      render: (text: any ,record: any) => (
        <Button
        size="small"
        className="resume-btn-sm"
        style={{
          backgroundColor: "rgb(209, 236, 241)",
          color: "rgb(12, 84, 96)",
          width: "55px",
          border: "none",
        }}
        onClick={() => onEdit(record)}
      
      >
        {text}
      </Button>
      ) ,
    },
    {
      sorter: (a: any, b: any) => a.assignee.localeCompare - b.assignee,
      title: "Assignees",
      dataIndex: "assignee",
      key: "assignee",
      width: "15%",
      showSorterTooltip: false,
      render: (assignee: any[]) => (
        // <AssigneeAvatar maxCount={2} data={assignee} />
        <AssigneeAvatar data={assignee} />
      ),
    },
    {
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      width: "40%",
      showSorterTooltip: false,
      render: (text: any, record: any) => (
       
          <Typography.Paragraph title={text} ellipsis>{text}</Typography.Paragraph>
       
      ),
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <>
          <Row justify={"end"}>
            <FormOutlined
              className="capTask-edit-btn"
              onClick={() => onEdit(record)}
            />
          </Row>
        </>
      ),
    },
  ];

  const ConfigProviderThemeProps = useMemo(
    () => ({
      components: {
        Select: {
          colorTextPlaceholder: "#95a5a6",
          fontSize: 14,
          colorPrimaryHover: "none",
          fontSizeIcon: 11,
          paddingSM: 12,
          paddingXS: 8,
          paddingXXS: 4,
          lineWidth: 1,
          borderRadius: 6,
          borderRadiusLG: 8,
          borderRadiusSM: 4,
          borderRadiusXS: 2,
          controlPaddingHorizontal: 12,
          controlPaddingHorizontalSM: 12,
          controlHeight: 30,
          controlHeightLG: 30,
          algorithm: true,
          fontWeightStrong: 600,
        },
        DatePicker: {
          controlHeight: 28,
          controlHeightLG: 28,

          hoverBorderColor: "#dee2e6",

          activeBorderColor: "#dee2e6",
        },
        Input: {
          controlHeight: 27,
          fontSizeIcon: 12,
          controlHeightLG: 27,
          inputFontSize: 14,
          colorTextPlaceholder: "#d9d9d9",
        },
      },
    }),
    []
  );
  return (
   
       <ConfigProvider theme={ConfigProviderThemeProps}>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/setting"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "Project",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"space-between"}>
            {/* {isUserAdmin() && ( */}
            <Button
              icon={<PlusOutlined />}
              className="capTask-btn btn-clr-primary btn-primary-md mb-1"
              size="small"
              onClick={() => {
                setShow("AddEditProject");
                setData(null);
              }}
              // disabled={!!isUserTeamLeader && !isUserAdmin}
            >
              Project
            </Button>
            {/* )} */}
          
            <Input
              className="custom-input mb-2"
              suffix={
                <SearchOutlined
                  style={{
                    color: "rgba(0, 0, 0, 0.45)",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={(e: any) => {
         

                setListParams((pre: any) => ({
                  ...pre,
                  projectNameSearch: e?.target?.value?.toLowerCase(),
                }));
                queryClient.invalidateQueries({
                  queryKey: ["projectList"],
                });
              }}
              placeholder="Search..."
              allowClear
              style={{ width: 220 }}
            />
          </Row>
        </Col>

        <Col span={24}>
          {isFetching || isLoading || isPending ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              className="capTask-custom-item"
              dataSource={apiProjectData?.list}
              columns={columns}
              onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
                total: apiProjectData?.totalRecords,
              }}
              scroll={{ y: 520 }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
              locale={{
                emptyText: (
                  <img
                    style={{ paddingTop: "80px", width: 140 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Row>

      {show === "AddEditProject" && (
        <AddEditProject onDismiss={() => setShow("")} data={data} />
      )}
  </ConfigProvider>
  );
};

export default ProjectList;
