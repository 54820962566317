import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  PaperClipOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Divider,
  Form,
  FormItemProps,
  FormProps,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  UploadProps,
} from "antd";
import React, { ReactNode, useMemo, useState } from "react";
import "./common.css";
import FilesUploader from "./FilesUploader";
import * as XLSX from "xlsx";
import { exportToExcel } from "../utils/fileUtils";
import { ModalTemplate } from "../pages/Dashboardtablecomponent/Modal/Modal";
import NoData from "../pages/NoData";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { ChildTaskCreationMethod, formatBytes } from "../utils/Util";
import { useSelector } from "react-redux";
import { selectedTaskMethod } from "../store/slices/task-slice";
import AddEditTask from "./AddEditTask";
interface IAttachmentList {
  data: any;
}
const AttachmentList = ({ data }: IAttachmentList) => {
  const columns = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ellipsis: true,
      render: (_: any, record: any) => {
        return <>{record?.name?.split(".")?.[0]}</>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Size",
      dataIndex: "index",
      key: "size",
      width: 150,
      render: (text: any, record: any) => formatBytes(record?.size),
    },
    // {
    //   title: "",
    //   key: "action",
    //   width: 30,
    //   render: (text: any, record: any) => (
    //     <Popconfirm
    //       title="Are you sure you want to delete this file?"
    //       onConfirm={() => {
    //         childForm.setFieldValue(
    //           [currentIndex, "attachments"],
    //           childForm
    //             .getFieldValue([currentIndex, "attachments"])
    //             ?.filter((x: any) => x?.data_url !== record?.data_url)
    //         );
    //         onDelete(currentIndex);
    //       }}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Button type="text" icon={<DeleteOutlined />} />
    //     </Popconfirm>
    //   )
    // }
  ];

  const dataSource =
    data !== null && data?.length > 0
      ? data?.map((itm: any, index: number) => ({
          key: `row-att-${index}`,
          name: itm?.file?.name,
          size: itm?.file?.size,
          type: itm?.file?.type,
        }))
      : [];
  return (
    <>
      {dataSource.length > 0 ? (
        <Table
          pagination={{
            showTotal: (total: any, range: any) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            position: ["bottomRight"],
          }}
          scroll={{ y: "20vh" }}
          className="capTask-custom-table mt-3"
          // scroll={{ y: 300 }}
          columns={columns}
          dataSource={dataSource}
          // pagination={false}
          loading={false}
          // className="capTask-custom-table"
        />
      ) : (
        <NoData message="No Attachments" />
      )}
    </>
  );
};

interface IAddEditChildTask {
  taskId: string | undefined | null;
  Form: FormProps;
  Upload?: UploadProps;
  children?: ReactNode;
  FormItem?: FormItemProps;
  Columns: any[];
  FormListName: string;
  fieldName: string;
  onChange?: () => void;
  cHeight?: number;
}

const AddEditParentTask = ({
  taskId = undefined,
  Form: FormProps,
  Columns,
  FormListName,
  fieldName,
  onChange = () => {},
  cHeight = 200,
}: IAddEditChildTask) => {
  const taskMode = useSelector(selectedTaskMethod);
  const [sourceForm] = Form.useForm(FormProps?.form);
  const [ChildTaskSheetData, setChildTaskSheetData] = useState<ImageListType>(
    []
  );
  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);
  const [isExcelModel, setIsExcelModel] = useState<boolean>(false);
  const [attachmentCount, setAttachmentCount] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openTaskDrawer, setOpenTaskDrawer] = useState<boolean>(false);
  const data = useMemo(() => {
    const totalItems = sourceForm?.getFieldValue(FormListName)?.length;
    return sourceForm
      ?.getFieldValue(FormListName)
      ?.map((itm: any, index: number) => ({ ...itm, sno: totalItems - index }));
  }, [sourceForm?.getFieldValue(FormListName)]);

  const deleteRow = (record: any) => {
    const newData = data?.filter((x: any) => x?.sno !== record?.sno);
    sourceForm?.setFieldsValue({
      parentTasks: [...newData],
    });
    setRefresh(!refresh);
    onChange();
  };

  const [xForm] = Form.useForm();
  const ExcelImport = async () => {
    return new Promise((resolve, reject) => {
      let file = ChildTaskSheetData[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          try {
            const binaryString = e.target.result;
            var workbook = XLSX.read(binaryString, {
              type: "binary",
            });
            var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
            var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, {
              header: 1,
            });
            resolve(jsonArr);
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsBinaryString(file.file as unknown as Blob);
      } else {
        reject("File not available");
      }
    });
  };

  const HandleExcelImport = async () => {
    try {
      const response = await ExcelImport();
      if (response && Array.isArray(response)) {
        let childTaskClone: any[] = [];
        response?.forEach((ele: any, index: number) => {
          if (index !== 0)
            childTaskClone.push({
              index: childTaskClone?.length,
              title: ele[ele?.length - 1],
              file: [],
              isSetParentData: true,
            });
        });
        childTaskClone = [
          ...childTaskClone,
          ...(sourceForm?.getFieldValue(FormListName) ?? []),
        ];
        sourceForm?.setFieldValue(FormListName, [...childTaskClone]);
        // setChildTasks(childTaskClone);
        setChildTaskSheetData([]);
        setIsExcelModel(false);

        // Success notification
        message.success({
          content:
            "The tasks have been successfully imported from the Excel file.",
          duration: 2,
        });
        onChange();
      } else {
        // Handle case where response is not as expected
        message.warning({
          // message: "Excel Import Warning",
          content:
            "The data from the Excel file is not in the expected format.",
          duration: 2,
        });
      }
    } catch (error: any) {
      // Error notification

      message.error({
        content: `An error occurred while importing the Excel file: ${error?.message}`,
        duration: 2, // You can adjust the duration if needed
      });
    }
  };

  const HandleExcelFormatExport = () => {
    let data = [["S.No", "Title"]];
    exportToExcel(data, "format.xlsx", "defaultSheet");
  };
  const addNew = () => {
    xForm.validateFields().then((values) => {
      const val = {
        ...values,
        isSetParentData: true,
      };
      if (Array.isArray(sourceForm?.getFieldValue(FormListName))) {
        sourceForm?.setFieldValue(FormListName, [
          val,
          ...sourceForm?.getFieldValue(FormListName),
        ]);
      } else {
        sourceForm?.setFieldValue(FormListName, [val]);
      }
      xForm.resetFields();
      setAttachmentCount(0);
      setRefresh(!refresh);
      onChange();
    });
  };
  console.log(
    " sourceForm?.getFieldValue(FormListName)",
    sourceForm?.getFieldValue(FormListName)
  );
  const onCloseAttachmentList = () => {
    // setTimeout(() => {
    setSelectedRecord(undefined);
    // }, 200);
  };
  return (
    <>
      {taskId && taskMode === ChildTaskCreationMethod.Drawer ? (
        <>
          <Row justify={"end"}>
            <Button
              type="link"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setOpenTaskDrawer(!openTaskDrawer);
              }}
            >
              Task
            </Button>
          </Row>
        </>
      ) : (
        <>
          <Form form={xForm} layout="vertical">
            <Row
              gutter={[0, 0]}
              justify={"space-between"}
              align={"middle"}
              style={{ marginBottom: 10 }}
            >
              <Col span={10}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name={"title"}
                  rules={[{ required: true, message: "Required Field!!" }]}
                >
                  <Input
                    size="small"
                    variant="outlined"
                    placeholder="Input title"
                    autoFocus
                    style={{ marginBottom: 0 }}
                    // className="borderLessInput"
                  />
                </Form.Item>
              </Col>

              <Col span={14}>
                <Space
                  split={<Divider type="vertical" style={{ margin: 0 }} />}
                >
                  <FilesUploader
                    Form={{ form: xForm }}
                    FormItem={{
                      style: { marginBottom: 0 },
                      className: "zeroHeightItem",
                    }}
                    fieldName={fieldName}
                    Upload={{
                      showUploadList: false,
                      onChange: (info) => {
                        setAttachmentCount(info?.fileList?.length);
                      },
                    }}
                    allowClear
                    extra={
                      <>
                        {attachmentCount ? (
                          <Popconfirm
                            title="Delete the attachment(s)"
                            description="Are you sure to delete attachment(s)?"
                            onConfirm={() => {
                              xForm?.resetFields([fieldName]);
                              setAttachmentCount(0);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              size="small"
                              danger
                              type="link"
                              icon={<DeleteOutlined />}
                            />
                          </Popconfirm>
                        ) : null}
                      </>
                    }
                  >
                    <Tooltip
                      title={
                        <>
                          {`${
                            attachmentCount === 0 ? "" : attachmentCount
                          } Attachments`}
                        </>
                      }
                    >
                      <Badge
                        dot={!!attachmentCount}
                        status="success"
                        offset={[0, 12]}
                      >
                        <Button
                          size="small"
                          type="link"
                          icon={<PaperClipOutlined />}
                        >
                          Attachment
                        </Button>
                      </Badge>
                    </Tooltip>
                  </FilesUploader>
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    size="small"
                    icon={<FileExcelOutlined />}
                    onClick={() => setIsExcelModel(true)}
                  >
                    Spreadsheet
                  </Button>

                  <Button
                    type="link"
                    size="small"
                    icon={<DownloadOutlined />}
                    onClick={HandleExcelFormatExport}
                  >
                    Download
                  </Button>

                  <Button
                    type="link"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={addNew}
                  >
                    Task
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <Table
        pagination={{
          showTotal: (total: any, range: any) =>
            `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: true,
          position: ["bottomRight"],
        }}
        scroll={{ y: cHeight }}
        className="capTask-custom-table mt-3 project-Create"
        columns={[
          {
            title: <Typography.Text ellipsis>S.No</Typography.Text>,
            key: "sno",
            dataIndex: "sno",
            width: "8%",
          },
          ...Columns,
          {
            dataIndex: "operation",
            width: "15%",
            render: (_: any, record: any, index: number) => (
              <>
                {!record?.id ? (
                  <Row
                    gutter={[0, 0]}
                    align={"middle"}
                    justify={"space-between"}
                  >
                    <Col>
                      <Form form={sourceForm}>
                        <FilesUploader
                          Form={{ form: FormProps?.form }}
                          FormItem={{
                            style: { marginBottom: 0 },
                            className: "zeroHeightItem",
                          }}
                          fieldName={[FormListName, index, fieldName]}
                          Upload={{
                            showUploadList: false,
                            style: { minHeight: 0 },
                            className: "zeroHeightItem",
                          }}
                          afterUpload={() => {
                            setRefresh(!refresh);
                          }}
                        >
                          <Button
                            size="small"
                            type="text"
                            icon={<PaperClipOutlined />}
                            onClick={() => {
                              // console.log(
                              //   "recordrecordrecordrecordrecordrecordrecordrecord = ",
                              //   record
                              // );
                            }}
                          />
                        </FilesUploader>
                      </Form>
                    </Col>
                    <Col>
                      <Button
                        type="text"
                        size="small"
                        //   danger
                        icon={<EyeOutlined />}
                        onClick={() => {
                          setSelectedRecord(record);
                        }}
                      >
                        <span style={{ fontSize: 12 }}>
                          {record?.[fieldName]?.length | 0}
                        </span>
                      </Button>
                    </Col>
                    <Col>
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        onConfirm={() => deleteRow(record)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="text"
                          size="small"
                          danger
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                ) : null}
              </>
            ),
          },
        ]}
        dataSource={data}
      />
      {isExcelModel && (
        <ModalTemplate
          closeModal={() => setIsExcelModel(false)}
          title="Add Excel"
          shouldShowFooter={true}
          buttonText="Upload"
          onClose={() => setChildTaskSheetData([])}
          onSubmit={HandleExcelImport}
        >
          <ImageUploading
            multiple
            value={ChildTaskSheetData}
            onChange={(file: ImageListType) => setChildTaskSheetData(file)}
            maxNumber={1}
            allowNonImageType={true}
            acceptType={["xlsx"]}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper">
                {imageList?.length > 0 ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {imageList?.map((item: any) => (
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ width: 400, height: 200 }}
                          src={process.env.PUBLIC_URL + "/xlxs.svg"}
                          alt=""
                        />
                        <p>{item?.file?.name}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    onClick={onImageUpload}
                    style={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    {...dragProps}
                  >
                    <NoData message={`${isDragging ? "Dragging" : ""}`} />
                    <Typography.Title
                      level={3}
                      style={{
                        margin: 0,
                        fontFamily: "Open Sans",
                        fontSize: 16,
                        color: "rgb(72 81 110)",
                      }}
                    >
                      Click and Upload Your File
                    </Typography.Title>
                  </div>
                )}
              </div>
            )}
          </ImageUploading>
        </ModalTemplate>
      )}
      {selectedRecord && (
        <ModalTemplate
          width="800px"
          open={!!selectedRecord}
          closeModal={() => onCloseAttachmentList()}
          title={`Attachment(s)`}
          shouldShowFooter={false}
          onClose={() => onCloseAttachmentList()}
        >
          <AttachmentList data={selectedRecord?.[fieldName]} />
        </ModalTemplate>
      )}
      {openTaskDrawer && (
        <AddEditTask
          disableFields={{
            projectIds: true,
          }}
          parentId={taskId}
          onDismiss={() => {
            setOpenTaskDrawer(!openTaskDrawer);
          }}
        />
      )}
    </>
  );
};
export default AddEditParentTask;
