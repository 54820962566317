import { Button, Form, Input, message, Modal, Tag } from "antd";

import { userData } from "../../../store/slices/user-slice";
import { useSelector } from "react-redux";
import { useSaveTaskLog } from "../../../Services/RQLogService";
import { usePostWeeklyReason } from "../../../Services/RQUserService";
interface IWeeklyActivityModel {
  daysInactive?: any;
  onSusses?: (rec: boolean) => void;
}
const WeeklyActivityModel = ({
  daysInactive,
  onSusses,
}: IWeeklyActivityModel) => {
  const user = useSelector(userData);
  const [form] = Form.useForm();
  const {
    mutateAsync: activityLogMutateAsync,
    isPending: isPendingWeeklyReason,
  } = usePostWeeklyReason();
  const { mutateAsync: taskLogMutateAsync, isPending: isPendingTask } =
    useSaveTaskLog("AssigneeLastActivity");
  const activityPayload = {
    assigneeName: user?.name,
    assigneeId: user?.id,
    uKey: "",
    cngType: 5,
    isComment: 2,
  };
  const handleSave = async () => {
    form.validateFields().then(async (values: any) => {
      try {
        const res = await activityLogMutateAsync({
          ...activityPayload,
          reason: values?.reason?.trim(),
        });
        if (res?.message) {
          message.error(`Error in save weekly summary => ${res?.message}`);
        } else {
          const logRes = await taskLogMutateAsync({
            logs: [
              {
                ...activityPayload,
                tid: res?.result?.id,
                pId: res?.result?.id,
                remarks: values?.reason?.trim(),
              },
            ],
          });
          if (logRes?.message)
            message.error(`Error in save logs => ${logRes?.message}`);
          else {
            onSusses && onSusses(true);
          }
        }
      } catch (error) {
        message.error("An error occurred while saving.");
      }
    });
  };
  return (
    <>
      <Modal
        closeIcon={null}
        footer={[
          <Button
            loading={isPendingTask || isPendingWeeklyReason}
            key="save"
            type="primary"
            onClick={handleSave}
            disabled={isPendingTask || isPendingWeeklyReason}
          >
            Save
          </Button>,
        ]}
        title={
          <>
            <Tag color="red"> Inactivity Alert :-</Tag>
            <span style={{ fontSize: 13, whiteSpace: "-moz-initial" }}>
              "You have not done any activity in{" "}
              <Tag bordered={false} color="processing">
                Capsitask
              </Tag>
              project since {""}
              {daysInactive || 0} days. Please explain the reason."
            </span>
          </>
        }
        open={true}
      >
        <Form form={form}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
              {
                whitespace: true,
                message: "This field is required",
              },
              {
                min: 20,
                message: "The reason must be at least 20 words",
              },
            ]}
          >
            <Input.TextArea
              maxLength={1000}
              autoSize={{ minRows: 5 }}
              placeholder="Type a reason..."
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default WeeklyActivityModel;
