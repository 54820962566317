import {
  Col,
  ConfigProvider,
  DatePicker,
  Input,
  message,
  Progress,
  Row,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useHighlyActiveProjectList } from "../../Services/RQDashboardService";
import TableSkeleton from "../../pages/dashboard/Common/TableSkeleton";
import { AreaChartOutlined, SearchOutlined } from "@ant-design/icons";
import { dayjs } from "../../utils/dayjs";
import HighlyActiveCharts from "./HighlyActiveCharts";
const HighlyActiveProject = ({ Echarts, SetEcharts }: any) => {
  const [searchHighlyActivePayload, setSearchHighlyActivePayload] = useState<{
    fromYear: number;
    toYear: number;
  }>({
    fromYear: dayjs().year(),
    toYear: dayjs().year(),
  });
  const { data, isFetching } = useHighlyActiveProjectList(
    searchHighlyActivePayload
  );
  const [listParams, setListParams] = useState({
    projectNameSearch: "",
  });

  const HighlyProjectlist = useMemo(() => {
    if (data?.message) message.error(`Error in => ${data?.message}`);
    const searchTerm = listParams?.projectNameSearch?.trim().toLowerCase();
    return (
      data?.result?.filter((project: any) =>
        project?.project?.name.toLowerCase().includes(searchTerm)
      ) || []
    );
  }, [data, listParams?.projectNameSearch]);
  const getStatus = useCallback((per: any) => {
    if (per === 0) return "red";
    else if (per > 0 && per <= 25) return "#ff4d4f";
    else if (per > 25 && per <= 75) return "#1677ff";
    else return "#52c41a";
  }, []);
  const columns: any = [
    {
      title: <Typography.Text>S. No.</Typography.Text>,
      dataIndex: "serial",
      key: "serial",
      width: 30,
      ellipsis: true,
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: "Projects",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a?.percentage - b?.percentage,
      width: 160,
      ellipsis: true,
      showSorterTooltip: false,
      render: (name: any, record: any) => (
        <Row gutter={[0, 0]} justify={"space-between"} align={"middle"}>
          <Col span={12}>
            <Typography.Paragraph ellipsis title={record?.project?.name}>
              {record?.project?.name}
            </Typography.Paragraph>
          </Col>
          <Col span={12}>
            <Progress
              status={
                getStatus(record?.percentage) === "red" ? "exception" : "active"
              }
              strokeColor={getStatus(record?.percentage)}
              percent={record?.percentage}
              size="small"
            />
          </Col>
        </Row>
      ),
    },
  ];

  const getDate = () => {
    let item = localStorage.getItem("highlyActiveProjectDate");
    if (item) {
      const parsedDate = dayjs(item);
      return parsedDate.isValid() ? parsedDate : dayjs();
    }
    return dayjs();
    
  };

  // const [Echarts, SetEcharts] = useState(false);
  return (
    <Row>
      <Col span={24}>
        <Row justify={"end"} className="mb-2">
          <Col span={24}>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    colorTextPlaceholder: "#95a5a6",
                    fontSize: 14,
                    colorPrimaryHover: "none",
                    fontSizeIcon: 10,
                    borderRadius: 4,
                    controlPaddingHorizontal: 4,
                    controlHeight: 27,
                    algorithm: true,
                    fontWeightStrong: 600,
                  },
                  DatePicker: {
                    controlHeight: 27,
                    controlHeightLG: 27,

                    hoverBorderColor: "#dee2e6",

                    activeBorderColor: "#dee2e6",
                  },
                },
              }}
            >
              {Echarts ? null : (
                <Row justify={"space-between"} align={"middle"}>
                  <Col xxl={8} xl={5} lg={3} className="fw600">
                    <Typography.Paragraph ellipsis>
                    <AreaChartOutlined
                        className="mr-2"
                        style={{ fontSize: "16px" }}
                        onClick={() => {
                          SetEcharts(true);
                        }}
                      />
                      Highly Active Project
                   
                    </Typography.Paragraph>
                  </Col>
                  <Col xxl={8} xl={8} lg={5}>
                    <Input
                      suffix={<SearchOutlined />}
                      placeholder="Search..."
                      allowClear
                      defaultValue={listParams?.projectNameSearch || ""}
                      onChange={(e: any) => {
                        setListParams((prev) => ({
                          ...prev,
                          projectNameSearch: e?.target?.value,
                        }));
                      }}
                      variant="borderless"
                      className="w100"
                      type="text"
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      disabledDate={(current) => {
                        return current && current >= dayjs();
                      }}
                      variant="borderless"
                      defaultValue={getDate()}
                      allowClear={false}
                      onChange={(date) => {
                        if (date) {
                          localStorage.setItem(
                            "highlyActiveProjectDate",
                            date.toISOString()
                          );
                          setSearchHighlyActivePayload((pre: any) => ({
                            ...pre,
                            fromYear: date.year(),
                            toYear: date.year(),
                          }));
                        }
                      }}
                      picker="year"
                    />
                  </Col>
                </Row>
              )}
            </ConfigProvider>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        {isFetching ? (
          <TableSkeleton
            columns={columns}
            scroll={{ y: "14vh" }}
            rows={5}
            style={{
              scrollbarWidth: "none",
              borderTop: "solid 1px #b2bec3",
            }}
          />
        ) : Echarts ? (
          <>
            <HighlyActiveCharts
              SetEcharts={SetEcharts}
              data={HighlyProjectlist}
            />
          </>
        ) : (
          <Table
            dataSource={HighlyProjectlist}
            columns={columns}
            pagination={false}
            scroll={{ y: 180 }}
            style={{
              borderTop: "solid 1px #b2bec3",
            }}
            locale={{
              emptyText: (
                <img
                  style={{ paddingBottom: "62px", width: 110 }}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
              ),
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default HighlyActiveProject;
