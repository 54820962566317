import { Button, Form, Input, message, Modal, Tag } from "antd";
import { userData } from "../store/slices/user-slice";
import { useSelector } from "react-redux";
import { usePostWeeklyReason } from "../Services/RQUserService";
import { useSaveTaskLog } from "../Services/RQLogService";
import { useEffect, useState } from "react";
import { useDeleteTask } from "../Services/RQBoardService";
import { AppRoutesPath } from "../Routes/AppRoutesPath";
import { useNavigate } from "react-router-dom";

interface IDeleteTaskModel {
  id: any;
  ukey: any;
  onSusses?: (rec: boolean) => void;
}

const DeleteTaskModel = ({ id, ukey, onSusses }: IDeleteTaskModel) => {
  const user = useSelector(userData);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const { mutateAsync: deleteTaskAsync, isPending: isDeleteTask } =
    useDeleteTask();
  const activityPayload = {
    assigneeName: user?.name,
    assigneeId: user?.id,
    uKey: "",
    cngType: 6,
    isComment: 2,
  };
  const { mutateAsync: taskLogMutateAsync, isPending: isPendingTask } =
    useSaveTaskLog("AssigneeLastActivity");
  const handleSave = async () => {
    form.validateFields().then(async (values: any) => {
      try {
        const res: any = await deleteTaskAsync({
          taskId: id,
          reason: values?.reason?.trim(),
        });

        if (res?.message) {
          message.error(`Error in Delete Task => ${res?.message}`);
        } else {
          const logRes = await taskLogMutateAsync({
            logs: [
              {
                ...activityPayload,
                tid: res?.result.id,
                pId: res?.result.projectIds?.[0],
                remarks: values?.reason?.trim(),
                uKey: res?.result?.uKey,
              },
            ],
          });
          if (logRes?.message)
            message.error(`Error in save logs => ${logRes?.message}`);
          else {
            onSusses && onSusses(true);
            setIsModalOpen(false);
            window?.location?.reload();
          }
        }
      } catch (error) {
        message.error("An error occurred while saving.");
      }
    });
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    onSusses && onSusses(false);
    window?.location?.reload();
  };

  return (
    <>
      <Modal
        closeIcon={null}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            loading={isPendingTask || isDeleteTask}
            key="save"
            type="primary"
            danger
            onClick={handleSave}
            disabled={isPendingTask || isDeleteTask}
          >
            Delete
          </Button>,
        ]}
        title={
          <>
            <Tag color="red">{ukey} :-</Tag>
            <span style={{ fontSize: 13, whiteSpace: "normal" }}>
              Please provide a reason for deleting this task to proceed.
            </span>
          </>
        }
        open={isModalOpen}
        onCancel={handleModalCancel}
      >
        <Form form={form}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
              {
                whitespace: true,
                message: "This field is required",
              },
              {
                min: 20,
                message: "The reason must be at least 20 characters.",
              },
            ]}
          >
            <Input.TextArea
              maxLength={200}
              autoSize={{ minRows: 3 }}
              placeholder="Type a reason..."
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteTaskModel;
