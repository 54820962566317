import {
  ArrowLeftOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  Input,
  message,
  Row,
  Table,
  TableProps,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import { memo, useMemo, useState } from "react";
import AddEditProjectGroup from "./AddEditProjectGroup";
import { useProjectGroupList } from "../../Services/RQMastersService";
import "../../common/common.css";
export interface IProjectGroup {}
const ProjectGroup = (props: IProjectGroup) => {
  const [show, setShow] = useState<string>("");
  const [groupId, setGroupId] = useState<string>("");
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
    search: "",
  });
  const { isLoading, data: projectGroupData } = useProjectGroupList(listParams);
  const apiData = useMemo(() => {
    if (projectGroupData?.message)
      message.error(`Error in user list => ${projectGroupData?.message}`);
    return projectGroupData?.result?.items?.map((itm: any) => ({
      ...itm,
      key: itm?.value,
    }));
  }, [projectGroupData]);

  const columns: any = [
    {
      title: "S. No.",
      dataIndex: "serial234",
      key: "serial",
      width: "9%",
      render: (text: string, record: any) => record?.sno,
    },
    {
      title: "Name",
      dataIndex: "name34",
      key: "name",
      width: "88%",
      render: (text: any, record: any) => (
        <Typography.Text
          ellipsis
          onClick={() => {
            setGroupId(record?.value);
            setShow("addEditProjectGroup");
          }}
          style={{ cursor: "pointer" }}
        >
          {record?.label}
        </Typography.Text>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "description",
      render: (text: any, record: any) => (
        <FormOutlined
          className="capTask-edit-btn"
          onClick={() => {
            setGroupId(record?.value);
            setShow("addEditProjectGroup");
          }}
        />
      ),
    },
  ];

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const [refresh, setRefresh] = useState<boolean>(false);
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    console.log("pagingData = ", paging);

    if (paging?.current !== undefined && paging?.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["15", "25", "50", "100"];

  const ConfigProviderThemeProps = useMemo(
    () => ({
      components: {
        Select: {
          colorTextPlaceholder: "#95a5a6",
          fontSize: 14,
          colorPrimaryHover: "none",
          fontSizeIcon: 11,
          paddingSM: 12,
          paddingXS: 8,
          paddingXXS: 4,
          lineWidth: 1,
          borderRadius: 6,
          borderRadiusLG: 8,
          borderRadiusSM: 4,
          borderRadiusXS: 2,
          controlPaddingHorizontal: 12,
          controlPaddingHorizontalSM: 12,
          controlHeight: 30,
          controlHeightLG: 30,
          algorithm: true,
          fontWeightStrong: 600,
        },
        DatePicker: {
          controlHeight: 28,
          controlHeightLG: 28,

          hoverBorderColor: "#dee2e6",

          activeBorderColor: "#dee2e6",
        },
        Input: {
          controlHeight: 27,
          fontSizeIcon: 12,
          controlHeightLG: 27,
          inputFontSize: 14,
          colorTextPlaceholder: "#d9d9d9",
        },
      },
    }),
    []
  );
  return (
    <ConfigProvider theme={ConfigProviderThemeProps}>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/setting"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "Project - Group",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Col span={18}>
              <Button
                icon={<PlusOutlined />}
                className="capTask-btn btn-clr-primary btn-primary-md mb-1"
                size="small"
                onClick={() => {
                  setShow("addEditProjectGroup");
                }}
              >
                Group
              </Button>
            </Col>
            <Col span={6}>
              <Row justify={"end"} gutter={[12, 0]}>
                <Col span={13}>
                  <Input
                    className="custom-input"
                    suffix={
                      <SearchOutlined
                        style={{
                          color: "rgba(0, 0, 0, 0.45)",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      />
                    }
                    placeholder="Search..."
                    allowClear
                    style={{ width: "100%" }}
                    onChange={(e: any) => {
                      setListParams((pre) => ({
                        ...pre,
                        search: e?.target?.value,
                      }));
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          {isLoading ? (
            <TableSkeleton
              className="capTask-custom-table mt-3"
              columns={columns}
              scroll={{ y: "70vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              className="capTask-custom-table mt-3 project-table"
              size="small"
              onChange={onChangeTable}
              pagination={{
                total: apiData?.totalRecords,
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              scroll={{ y: "70vh" }}
              columns={columns}
              dataSource={apiData}
              locale={{
                emptyText: (
                  <img
                    style={{ paddingTop: "40px", width: 140 }}
                    // src={process.env.PUBLIC_URL + "/noTask.svg"}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Row>
      {show === "addEditProjectGroup" && (
        <AddEditProjectGroup
          groupId={groupId}
          onDismiss={() => {
            setGroupId("");
            setShow("");
          }}
        />
      )}
    </ConfigProvider>
  );
};

export default memo(ProjectGroup);
