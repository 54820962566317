import React, { useEffect, useMemo, useState } from "react";
import "../../common/common.css";
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  message,
  Row,
  Select,
  Tooltip,
  TreeSelect,
} from "antd";
import {
  CloseOutlined,
  CloudDownloadOutlined,
  FilterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { AddEditApis, getCsvDownload } from "../../Services/RQTaskService";
import { useSelector } from "react-redux";
import { projectData, setProjectData } from "../../store/slices/project-slice";
import { userData } from "../../store/slices/user-slice";
import { useDispatch } from "react-redux";
import { statusconvertor } from "../../utils/Util";
import saveAs from "file-saver";
import {
  useProjectGroupById,
  useProjectGroupList,
  useProjectGroupWithProjectList,
} from "../../Services/RQMastersService";

export interface ITaskFilter {
  project: { label: string; value: string }[];
  SearchText: string;
  status: number[];
  priority: number;
  // userFilter: IUserFilter;
}
export interface ITaskViewMode {}
export enum userType {
  All,
  Assignee,
  AssignedBy,
  Reporter,
}
interface IUserFilter {
  userType: userType;
  id: string;
}

interface IFormData {
  project: { label: string; value: string }[];
  SearchText: string;
  status: number[];
  priority: number;
  userFilter: IUserFilter;
  start: number;
  length: number;
}
interface ITaskSearchFilter {
  style?: React.CSSProperties;
  onChange?: (filters: ITaskFilter) => void;
  onClear?: () => void;
  onDownload?: () => void;
}

const IssueSearchFilter = ({
  onChange,
  onClear,
  style,
  onDownload,
}: ITaskSearchFilter) => {
  const [form] = Form.useForm();
  const project = useSelector(projectData);
  const user = useSelector(userData);

  const [searchFilter, setSearchFilter] = useState<IFormData>({
    project: [
      {
        label: project?.name,
        value: project?.id,
      },
    ],
    SearchText: "",
    status: [0, 1, 2, 3, 4, 5, 7],
    priority: -1,
    userFilter: { userType: userType.All, id: user?.id },
    start: 0,
    length: 15,
  });

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const values = Form.useWatch([], form);
  const { useUser, useProject } = AddEditApis;
  React.useEffect(() => {
    form
      .validateFields()
      .then((val: any) => {
        let data: ITaskFilter & ITaskViewMode = {
          ...val,
          // project: val?.project?.map((item:any)=>item?.value),
          priority: val?.priority?.value ?? -1,
          userFilter: val?.userFilter ?? {
            userType: 0,
            id: user?.id,
          },
          start: form.getFieldValue("start"),
          length: form.getFieldValue("length"),
          // projects:val?.projects?.map((itm:any)=>itm?.value),
          status: val?.status ?? [0, 1, 2, 3, 4, 5, 7],
        };

        onChange && onChange(data);
      })
      .catch((r: any) => {});
  }, [form, values]);
  const priorityOptions = [
    { label: "All", value: -1 },
    { label: "High", value: 2 },
    { label: "Medium", value: 1 },
    { label: "Low", value: 0 },
  ];
  const { data: userDataList, isPending: isUserPending } =
    useUser(searchFilter);
  // console.log("userDataListuserDataList = ", userDataList);

  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [userDataList]);

  const { data: projectDataList, isPending: isProjectPending } =
    useProjectGroupWithProjectList({
      id: user?.id,
      ...searchFilter?.project,
    });
  const projectOptions = useMemo(() => {
    let dataSet: any[] = [];

    for (let itm of projectDataList?.result?.items || []) {
      if (!!itm?.Group) {
        let val: any = {
          title: itm?.Group?.label,
          value: itm?.Group?.value,
          key: itm?.Group?.value,
        };
        if (!!itm?.List && Array.isArray(itm?.List) && itm?.List?.length > 0) {
          val.children = itm?.List?.map((item: any) => ({
            title: item?.label,
            value: item?.value,
            key: item?.value,
          }));
        }
        dataSet.push(val);
      } else {
        for (let singleItem of itm?.List || []) {
          dataSet.push({
            title: singleItem?.label,
            value: singleItem?.value,
            key: singleItem?.value,
          });
        }
      }
    }
    return dataSet.sort(
      (a: any, b: any) => (b?.children ? 1 : -1) - (a?.children ? 1 : -1)
    );
  }, [projectDataList]);

  // const [listParams, setListParams] = useState({
  //   start: 0,
  //   length: 15,
  //   search: "",
  // });

  // const { isLoading, data: projectGroupData } = useProjectGroupList(listParams);

  // const apiData = useMemo(() => {
  //   if (projectGroupData?.message)
  //     message.error(` => ${projectGroupData?.message}`);
  //   return projectGroupData?.result?.items?.map((itm: any) => ({
  //     ...itm,
  //     key: itm?.value,
  //   }));
  // }, [projectGroupData]);

  // const [groupId, setGroupId] = useState<string>("");
  // const { data: getByIdApiData, isPending: isGetByIdPending } =
  //   useProjectGroupById(groupId);
  // const projectOpts = useMemo(() => {
  //   if (getByIdApiData?.message) {
  //     message.error(`Error => ${getByIdApiData?.message}`);
  //     return undefined;
  //   } else
  //     return getByIdApiData?.result?.projects?.map((itm: any) => ({
  //       ...itm,
  //       key: itm?.id,
  //       label: itm?.name,
  //       value: itm?.id,
  //     }));
  // }, [getByIdApiData]);

  // const filterTreeData = (data:any)=>{
  //   return data.map((item:any) => {
  //     if (item.children) {
  //       return {
  //         ...item,
  //         children: filterTreeData(item.children),
  //         treeCheckable: true,  // Display checkbox if there are children
  //       };
  //     }
  //     return {
  //       ...item,
  //       treeCheckable: false,  // No checkbox if no children
  //     };
  //   });
  // }

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorTextPlaceholder: "#d9d9d9",
              fontSize: 14,
              colorPrimaryHover: "none",
              fontSizeIcon: 12,
              paddingSM: 8,
              paddingXS: 8,
              paddingXXS: 4,
              lineWidth: 1,
              borderRadius: 4,
              borderRadiusLG: 4,
              borderRadiusSM: 4,
              borderRadiusXS: 4,
              controlPaddingHorizontal: 4,
              controlPaddingHorizontalSM: 4,
              controlHeight: 28,
              controlHeightLG: 27,
              algorithm: true,
              fontWeightStrong: 600,
            },
            Input: {
              controlHeight: 27,
              fontSizeIcon: 12,
              controlHeightLG: 27,
              inputFontSize: 14,
              colorTextPlaceholder: "#d9d9d9",
            },
            TreeSelect: {
              paddingXS: 10,
            },
          },
        }}
      >
        <div style={style}>
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              project: [
                {
                  label: project?.name,
                  value: project?.id,
                },
              ],
              SearchText: "",
              // status: [0, 1, 2, 3, 4, 5, 7],
              priority: -1,
              userFilter: { userType: userType.All, id: user?.id },
              taskType: 1,
              start: 0,
              length: 15,
            }}
          >
            <Row gutter={[16, 16]} align="middle" justify={"start"}>
              <Col style={{ cursor: "pointer" }} span={4}>
                <Form.Item className="mb-0" name={"project"}>
                  <TreeSelect
                    treeLine
                    showCheckedStrategy="SHOW_ALL"
                    treeCheckable
                    multiple
                    maxTagCount="responsive"
                    labelInValue
                    style={{ width: "100%" }}
                    className="issueReport-custom-select mt-2 "
                    treeData={projectOptions}
                    placeholder="Select a Project"
                    showSearch
                    onSearch={(value) => {
                      setSearchFilter((pre: any) => ({
                        ...pre,
                        project: { search: value },
                      }));
                    }}
                    onBlur={() => {
                      setSearchFilter((prev: any) => ({
                        ...prev,
                        project: {
                          search: null,
                        },
                      }));
                    }}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{ pointerEvents: "none" }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>Hover Me</span>
                      </Tooltip>
                    )}
                    treeNodeFilterProp="title"
                  />
                </Form.Item>
              </Col>
              <Col style={{ cursor: "pointer" }} span={4}>
                <Form.Item className="mb-0" name={"taskType"}>
                  <Select
                    style={{ width: "100%" }}
                    className="issueReport-custom-select mt-2 "
                    options={[
                      {
                        label: "Single Task",
                        value: 1,
                      },
                      {
                        label: "Parent-Child Task",
                        value: 2,
                      },
                    ]}
                    placeholder="Select a Project"
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col className="mt-2" span={16}>
                <Row gutter={[0, 0]} justify={"end"} align={"middle"}>
                  <Col>
                    <Form.Item className="mb-0" name={"SearchText"}>
                      <Input
                        className="custom-input"
                        suffix={
                          <SearchOutlined
                            style={{
                              color: "rgba(0, 0, 0, 0.45)",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          />
                        }
                        placeholder="Search..."
                        allowClear
                        style={{ width: 180 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      className="custom-button mx-2"
                      style={{
                        height: 29,
                        borderColor: "#d3e3ff",
                        borderRadius: "4px",
                        backgroundColor: "#f3f4fa",
                      }}
                      icon={<FilterOutlined style={{ color: "#377dff" }} />}
                      onClick={() => {
                        setShowFilters(!showFilters);
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      className="mr-2 "
                      style={{
                        height: 29,
                        borderColor: "#d3e3ff",
                        borderRadius: "4px",
                        backgroundColor: "#f3f4fa",
                      }}
                      size="small"
                      onClick={onDownload}
                    >
                      <CloudDownloadOutlined
                        style={{ color: "#377dff", fontSize: 16.8 }}
                      />
                      <span style={{ color: "#377dff", fontSize: 13.5 }}>
                        Download
                      </span>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="flexContainer"
                      type="text"
                      style={{ height: 27, marginRight: 36 }}
                      onClick={() => {
                        form.resetFields();
                      }}
                    >
                      <ReloadOutlined style={{ fontSize: 15 }} />
                      Refresh
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            {showFilters && (
              <Row
                justify={"space-between"}
                align={"middle"}
                gutter={[0, 0]}
                className="mt-1 mb-1"
                style={{
                  backgroundColor: "rgba(247, 248, 255, 0.5)",
                  // margin: 8,
                  marginRight: 37,

                  borderRadius: 4,
                }}
              >
                <Col span={20}>
                  <Row justify={"start"} gutter={[10, 0]}>
                    <Col span={5}>
                      <Form.Item className="mb-0" name={"userType"}>
                        <Select
                          labelInValue
                          className="capTask-custom-select w100"
                          options={[
                            // { label: "All", value: userType.All },
                            { label: "Assignee", value: userType.Assignee },
                            { label: "AssignedBy", value: userType.AssignedBy },
                            { label: "Reporter", value: userType.Reporter },
                          ]}
                          allowClear
                          // onClear={()=>{
                          //   form.resetFields()
                          // }}
                          placeholder="User type"
                          showSearch
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item className="mb-0" name={"priority"}>
                        <Select
                          labelInValue
                          className="capTask-custom-select"
                          placeholder="Priority"
                          options={priorityOptions}
                          showSearch
                          // onClear={()=>{
                          //   form.resetFields()
                          // }}

                          maxTagCount={"responsive"}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item className="mb-0" name={"status"}>
                        <TreeSelect
                          className="issueReport-custom-select mr-3"
                          placeholder="Select status"
                          treeData={[
                            { title: statusconvertor(1), value: 1 },
                            { title: statusconvertor(2), value: 2 },
                            { title: statusconvertor(3), value: 3 },
                            { title: statusconvertor(7), value: 7 },
                            { title: statusconvertor(4), value: 4 },
                            { title: statusconvertor(5), value: 5 },
                            { title: statusconvertor(6), value: 6 },
                          ]}
                          defaultValue={[
                            { title: statusconvertor(1), value: 1 },
                            { title: statusconvertor(2), value: 2 },
                            { title: statusconvertor(3), value: 3 },
                            { title: statusconvertor(7), value: 7 },
                            { title: statusconvertor(4), value: 4 },
                            { title: statusconvertor(5), value: 5 },
                          ]}
                          // onChange={(value: any) => {
                          //   setFormData((prev: any) => ({
                          //     ...prev,
                          //     status: value.map((item: any) => item),
                          //   }));
                          // }}
                          treeCheckable
                          maxTagCount="responsive"
                          showSearch
                          // onSearch={onSearch}
                          treeNodeFilterProp="title"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item className="mb-0" name="id">
                        <Select
                          labelInValue
                          className="issueReport-custom-select mr-3"
                          style={{ width: 200 }}
                          placeholder="Select assignee"
                          showSearch
                          maxTagCount={"responsive"}
                          options={reporterOptions}
                          optionFilterProp="label"
                          allowClear
                          //   searchValue={searchFilter?.assignee?.search}
                          // onClear={()=>{
                          //   form.resetFields()
                          // }}
                          onSearch={(value) => {
                            setSearchFilter((pre: any) => ({
                              ...pre,
                              user: {
                                search: value,
                              },
                            }));
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row justify={"end"} align={"middle"}>
                    <Col>
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#eef3fa",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                        }}
                        icon={
                          <ReloadOutlined
                            style={{
                              color: "rgb(211, 18, 18)",
                              fontSize: "13px",
                            }}
                          />
                        }
                        onClick={() => {
                          form.resetFields();
                        }}
                      />
                    </Col>
                    <Col>
                      <Button
                        className="mx-2"
                        style={{
                          backgroundColor: "#eef3fa",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                        }}
                        // shape="circle"
                        type="text"
                        icon={<CloseOutlined className="CloseOutlinedIcon" />}
                        onClick={() => {
                          setShowFilters(!showFilters);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </ConfigProvider>
    </>
  );
};
export default React.memo(IssueSearchFilter);
