import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, Input, Row, Select } from "antd";
import "../../common/common.css";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { projectData } from "../../store/slices/project-slice";
import { userData } from "../../store/slices/user-slice";
import { AddEditApis } from "../../Services/RQTaskService";
import { isUserAdmin } from "../../utils/Util";
interface ISearch {
  user: {
    search: undefined;
  };
  assignee: {
    search: undefined;
  };
  project: {
    search: string;
    length: number;
  };
  sprint: {
    search: undefined;
  };
}
export const ToothbarReport = ({
  setprojectuserarr,
  projectuserarrfilter,
  setdefaultCount,
  defaultCount,
  setReloadOutlined,
  setProjectAssigneeId,
}: any) => {
  const handlesearchfilter = (value: string) => {
    let filtereddata = projectuserarrfilter?.filter((item: any) => {
      if (
        item?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(value?.toLowerCase())
      ) {
        return item;
      }
    });
    setprojectuserarr(filtereddata);
  };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const project = useSelector(projectData);
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>({ label: project?.name, value: project?.id });

  const [searchFilter, setSearchFilter] = useState<ISearch>({
    user: {
      search: undefined,
    },
    assignee: {
      search: undefined,
    },
    project: {
      search: "",
      length: 200,
    },
    sprint: {
      search: undefined,
    },
  });
  const { useProject } = AddEditApis;
  const user = useSelector(userData);
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#d9d9d9",
            fontSize: 14,
            colorPrimaryHover: "none",
            fontSizeIcon: 12,
            paddingSM: 8,
            paddingXS: 8,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 28,
            controlHeightLG: 27,
            algorithm: true,
            fontWeightStrong: 600,
          },
          Input: {
            controlHeight: 26,
            fontSizeIcon: 12,
            controlHeightLG: 27,
            inputFontSize: 14,
            colorTextPlaceholder: "#d9d9d9",
          },
        },
      }}
    >
      <Row gutter={[16, 16]} align="middle" justify={"space-between"}>
        <Col span={12}>
          <Row>
            <Col className="mr-3">
              <Input
                placeholder="Search..."
                allowClear
                onChange={(e: any) => handlesearchfilter(e?.target?.value)}
                type="text"
                style={{ width: "190px" }}
                suffix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0, 0, 0, 0.45)",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  />
                }
              />
            </Col>
            {isUserAdmin() && (
              <Col span={9}>
                <Select
                  style={{ width: 280 }}
                  labelInValue
                  className="capTask-custom-select"
                  placeholder="Select a Project"
                  loading={isProjectPending}
                  options={projectOptions}
                  showSearch
                  onSearch={(value) => {
                    setSearchFilter((pre: any) => ({
                      ...pre,
                      project: {
                        search: value,
                        length: 200,
                      },
                    }));
                  }}
                  onClear={() => {
                    setProjectAssigneeId(null);
                  }}
                  optionFilterProp="label"
                  onSelect={(value: any) => {
                    setProjectAssigneeId({
                      label: value?.label,
                      value: value?.value,
                    });
                    setSelectedProject({
                      label: value?.label,
                      value: value?.value,
                    });
                    setSearchFilter((pre: any) => ({
                      ...pre,
                      project: {
                        search: "",
                        length: 200,
                      },
                    }));
                  }}
                  onBlur={() => {
                    setSelectedProject((pre: any) => ({
                      ...pre,
                      project: {
                        search: "",
                        length: 200,
                      },
                    }));
                  }}
                  allowClear
                />
              </Col>
            )}
          </Row>
        </Col>

        <Col>
          <Row>
            <Select
              className="capTask-custom-select"
              style={{
                width: 180,
                margin: "0px 20px",
              }}
              value={defaultCount}
              options={[
                { label: "All", value: 0 },
                { label: "Active", value: 1 },
                { label: "InActive", value: 2 },
              ]}
              onChange={(item: any) => {
                setdefaultCount(item);
              }}
              showSearch
              onSearch={onSearch}
              optionFilterProp="label"
            />
            <Col>
              <Button
                className="flexContainer"
                type="text"
                style={{ height: 27 }}
                onClick={() => {
                  setReloadOutlined((prevState: any) => ({
                    ...prevState,
                  }));
                }}
              >
                <ReloadOutlined style={{ fontSize: 15 }} />
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </ConfigProvider>
  );
};
