import React, { useMemo } from "react";
import { Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactECharts from "echarts-for-react";
const HighlyActiveCharts = ({ SetEcharts, data }: any) => {
  const chartOptions = useMemo(() => {
    const projectNames = data?.map((item: any) => item?.project?.name);
    const percentages = data?.map((item: any) => item?.percentage);

    return {
      tooltip: { trigger: "axis" },
      legend: {},
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {},
          dataView: { readOnly: false },
          magicType: { type: ["line", "bar"] },
          restore: {},
        },
      },
      xAxis: {
        type: "category",
        data: projectNames,
      },
      yAxis: {
        type: "value",
        axisLabel: { formatter: "{value} %" },
      },
      series: [
        {
          name: " Activity Percentage",
          type: "bar",
          data: percentages,
          itemStyle: {
            color: (params: any) =>
              params?.value <= 25 ? "#e74c3c" : "#1890ff",
          },
          
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          markLine: { data: [{ type: "average", name: "Avg" }] },
        },
      ],
    };
  }, [data]);
  return (
    <Row className="mr-3">
      <Col span={24}>
        <>
          <ArrowLeftOutlined
            className="ml-4 custom-iconn"
            style={{ fontSize: "12px" }}
            onClick={() => {
              SetEcharts(false);
            }}
          />
          <ReactECharts option={chartOptions} style={{ height: 265 }} />
        </>
      </Col>
    </Row>
  );
};

export default HighlyActiveCharts;
