import React from "react";

import { Button, Typography, Result } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppRoutesPath } from "../Routes/AppRoutesPath";

const NotFound: React.FC = () => {
  const { Title, Text } = Typography;
  const location = useLocation(); // React Hook
  let url = location?.pathname?.substring(1)?.split("/")[0];
  const navigate = useNavigate();

  return (
    <div style={{ padding: "50px" }}>
      <Result
        className="NotFound"
        status="404"
        title={<Title level={2}>404 - Page Not Found</Title>}
        subTitle={
          <Text>Sorry, the page you are looking for does not exist.</Text>
        }
        extra={
          <button
            className="NotFound-button"
            onClick={() => {
              if (url !== AppRoutesPath.dashboard) {
                navigate(AppRoutesPath.dashboard);
              }
            }}
          >
            Back Home
          </button>
        }
      />
    </div>
  );
};

export default NotFound;
