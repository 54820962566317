import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  message,
  Row,
  Select,
  TreeSelect,
  Typography,
} from "antd";
import { statusconvertor } from "../../utils/Util";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import "../../components/Reports/Reports.css";
import { ReloadOutlined } from "@ant-design/icons";
import { AddEditApis } from "../../Services/RQTaskService";
import HTTPSCalls from "../../Services/HTTPCalls";
const InhandSearchFilter = ({
  fetchInHandData,
  statusFilter,
  setstatusFilter,
  projectid,
}: any) => {
  const [filterData, setFilterData] = useState<any>({
    projectid: "",
    assigneeId: "",
    status: 3,
  });
  const [form] = Form.useForm();
  const [AssigneeAvatarData, setAssigneeAvatarData] = useState([]);

  const [AssigneeSelect, setAssigneeSelect] = useState("");
  const user = useSelector(userData);
  const { useProject } = AddEditApis;
  const [searchFilter, setSearchFilter] = useState<{
    user: {
      search: undefined;
    };
    project: {
      search: undefined;
    };
  }>({
    user: {
      search: undefined,
    },
    project: {
      search: undefined,
    },
  });
  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  //GetUser Api
  const FetchAssignee = () => {
    HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.InHandReports.getAssignees + "?", {
      length: 200,
    }).then((res: any) => {
      let RANDOM_USERS: any = [];
      if (res?.result?.length > 0) {
        for (let el in res?.result) {
          RANDOM_USERS.push({
            id: res?.result[el]?.id,
            name: res?.result[el]?.name,
            thumb: res?.result[el]?.imageThumb,
          });
        }
        let assigneeavatar: any = [{ label: "All", value: "", key: "all" }];
        res?.result?.map((item: any) => {
          assigneeavatar?.push({
            label: item?.name,
            value: item?.id,
            key: item?.id, // Assuming id is unique
            ...item,
          });
        });
        setAssigneeAvatarData(assigneeavatar);
      }
    });
  };

  useEffect(() => {
    FetchAssignee();
  }, [projectid]);
  const handleSearchClick = () => {
    form
      .validateFields()
      .then((values) => {
        if (!filterData?.projectid) {
        } else {
          fetchInHandData(filterData?.projectid, AssigneeSelect, statusFilter);
        }
      })
      .catch((errorInfo) => {
        message.error({
          content: "Please select a project before proceeding.",
          duration: 2,
        });
      });
  };
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#d9d9d9",
            fontSize: 14,
            colorPrimaryHover: "none",
            fontSizeIcon: 12,
            paddingSM: 8,
            paddingXS: 8,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 28,
            controlHeightLG: 27,
            algorithm: true,
            fontWeightStrong: 600,
          },
          Input: {
            controlHeight: 27,
            fontSizeIcon: 12,
            controlHeightLG: 27,
            inputFontSize: 14,
            colorTextPlaceholder: "#d9d9d9",
          },
          TreeSelect: {
            paddingXS: 10,
          },
        },
      }}
    >
      <Row justify={"space-between"} gutter={[16, 16]}>
        {/* Form Component */}
        <Col span={15}>
          <Form layout="inline" form={form}>
            <Form.Item name="project" rules={[{ required: true, message: "" }]}>
              <Select
                className="issueReport-custom-select"
                style={{ width: "10vw" }}
                loading={isProjectPending}
                searchValue={searchFilter?.project?.search}
                options={projectOptions}
                onSelect={(value: any) => {
                  setFilterData({
                    ...filterData,
                    projectid: value,
                  });
                }}
                placeholder="Choose Project"
                showSearch
                onSearch={(value) => {
                  setSearchFilter((pre: any) => ({
                    ...pre,
                    project: {
                      search: value,
                    },
                  }));
                }}
                optionFilterProp="label"
              />
            </Form.Item>

            <Form.Item name="Status">
              <div>
                <Typography.Text className="mr-4 HideHand">
                  Status
                </Typography.Text>
                <TreeSelect
                  treeData={[
                    { title: statusconvertor(1), value: 1 },
                    { title: statusconvertor(2), value: 2 },
                    { title: statusconvertor(3), value: 3 },
                    { title: statusconvertor(7), value: 7 },
                    { title: statusconvertor(4), value: 4 },
                    { title: statusconvertor(5), value: 5 },
                    { title: statusconvertor(6), value: 6 },
                  ]}
                  defaultValue={[
                    { title: statusconvertor(1), value: 1 },
                    { title: statusconvertor(2), value: 2 },
                    { title: statusconvertor(3), value: 3 },
                    { title: statusconvertor(7), value: 7 },
                    { title: statusconvertor(4), value: 4 },
                    { title: statusconvertor(5), value: 5 },
                  ]}
                  value={statusFilter}
                  treeCheckable
                  showCheckedStrategy="SHOW_PARENT"
                  placeholder="Status Filter"
                  maxTagCount={1}
                  onChange={setstatusFilter}
                  className="issueReport-custom-select"
                  style={{ width: "180px" }}
                  showSearch
                  onSearch={onSearch}
                  treeNodeFilterProp="title"
                />
              </div>
            </Form.Item>

            <Form.Item name="Assignee">
              <div>
                <Typography.Text className="mr-4 mt-1 HideHand">
                  Assignee
                </Typography.Text>
                <Select
                  className="issueReport-custom-select mt-1"
                  style={{ width: "10vw" }}
                  placeholder="Please Select"
                  defaultValue={"All"}
                  options={AssigneeAvatarData}
                  onChange={(value) => {
                    setAssigneeSelect(value);
                  }}
                  showSearch
                  allowClear
                  onSearch={onSearch}
                  optionFilterProp="label"
                />
              </div>
            </Form.Item>
          </Form>
        </Col>
        <Col>
          <Row>
            <Button
              className="pl-5 pr-5 mr-4"
              type="primary"
              style={{ borderRadius: "3px", height: "28px" }}
              size={"small"}
              onClick={handleSearchClick}
            >
              Search
            </Button> 
              
            <Col>
              <Button
                className="flexContainer"
                type="text"
                style={{ height: 27 }}
                onClick={handleSearchClick}
              >
                <ReloadOutlined style={{ fontSize: 15 }} />
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default InhandSearchFilter;
